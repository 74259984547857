// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as c3 from "c3"
import data from "../../javascript/data.json";

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import { Graph } from "graphology"
import { Sigma } from "sigma"

import FA2Layout from "graphology-layout-forceatlas2/worker";
import forceAtlas2 from "graphology-layout-forceatlas2";

// import the bootstrap javascript module
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')

// import the application.scss we created for the bootstrap CSS (if you are not using assets stylesheet)
import "../stylesheets/application.scss"
import internal from "@rails/actioncable/src/internal";

document.addEventListener("turbolinks:load", function() {

    $('#myModal').modal();

    //menu toggle
    (function($){
        $('.nav-toggle').click(function(e){
            e.preventDefault();
            $('.nav-toggle').toggleClass('active');
            $('.nav-menu').toggleClass('active');

        })
    })(jQuery);

    var chart = c3.generate({
        bindto: '#chart',
        title: {
            text: ''
        },
        transition: {
            duration: 2000
        },
        data: {
            x: 'Datum',
            xFormat: '%Y-%m-%d %H:%M',
            url: '/opendata-aasee.json',
            type: 'area-step',
            axes: {'Datum':'x'},
            mimeType: 'json',
            keys: {
                x: 'Datum',
             value: ['Wassertemperatur','pH-Wert','Sauerstoffgehalt']
            }
        },
        zoom: {
            enabled: true,
        },
        axis: {
            x: {
                type: 'timeseries',
                tick: {
                    format: '%d.%m.%Y %H:%M'
                }
            }
        }
    })

    const button = document.getElementById("animation_1")
    const button2 = document.getElementById("animation_2")
    const button3 = document.getElementById("animation_3")
    const button4 = document.getElementById("animation_4")
    let turnedOn = false
    let myinterval = null

    button.addEventListener('click', e => {
            animation_1()
            e.currentTarget.textContent = 'Animation läuft'
            button.style.background = '#c2e05e'
            button.style.color = '#000'
            setTimeout(function () {
                button.innerHTML = "Animieren"
                button.style.background = '#198754'
                button.style.color = '#fff'
            }, 17500)

    })

    button2.addEventListener('click', e => {
        animation_2()
        e.currentTarget.textContent = 'Animation läuft'
        button2.style.background = '#c2e05e'
        button2.style.color = '#000'
        setTimeout(function () {
            button2.innerHTML = "Animieren"
            button2.style.background = '#198754'
            button2.style.color = '#fff'
        }, 9500)

    })

    button3.addEventListener('click', e => {
        animation_3()
        e.currentTarget.textContent = 'Animation läuft'
        button3.style.background = '#c2e05e'
        button3.style.color = '#000'
        setTimeout(function () {
            button3.innerHTML = "Animieren"
            button3.style.background = '#198754'
            button3.style.color = '#fff'
        }, 17500)

    })

    function animation_1(){
        setTimeout(function () {
            chart.transform('area-spline');
        }, 1000);

        setTimeout(function () {
            chart.toggle('pH-Wert', {withLegend: true});
        }, 3500);

        setTimeout(function () {
            chart.toggle('Wassertemperatur', {withLegend: true});
        }, 6500);

        // setTimeout(function () {
        //     chart.transform('gauge');
        // }, 10500);

        setTimeout(function () {
            chart.toggle('Wassertemperatur', {withLegend: true});
            chart.toggle('pH-Wert', {withLegend: true});
        }, 8000);

        setTimeout(function () {
            chart.transform('area-step');
        }, 10000);
    }

    var chart2 = c3.generate({
        bindto: '#chart2',
        title: {
            text: ''
        },
        data: {
            columns: [
                ['Gesamt Anträge A', 452],
                ['Gesamt Anträge B', 268],
                ['Gesamt Anträge C', 234],
                ['Gesamt Anträge D', 125],
                ['Gesamt Anträge E', 10]
            ],
            type: 'pie'
        },
        zoom: {
            enabled: true
        }
    });

    function animation_2(){

        setTimeout(function () {
            chart2.toggle('Gesamt Anträge B', {withLegend: true});
        }, 1000);

        setTimeout(function () {
            chart2.toggle('Gesamt Anträge C', {withLegend: true});
        }, 2500);


        setTimeout(function () {
             chart2.transform('donut');
         }, 4000);

        setTimeout(function () {
             chart2.transform('pie');
         }, 6000);

        setTimeout(function () {
            chart2.toggle('Gesamt Anträge B', {withLegend: true});
        }, 7000);

        setTimeout(function () {
            chart2.toggle('Gesamt Anträge C', {withLegend: true});
        }, 8500);
    }

    var chart3 = c3.generate({
        bindto: '#chart3',
        title: {
            text: ''
        },
        transition: {
        duration: 2000
        },
        data: {
            url: '/opendata-rheinpegel-data.json',
            type: 'area',
            y: 'Tag',
            hide: ['Tag'],
        },
        zoom: {
            enabled: true
        },
        legend: {
        hide: ['Tag']
    },
        axis: {
            y: {
            },
            x: {
                label: 'Tag',
            }
        }
    });

    function animation_3(){
        chart3.zoom([1.3120085604846417,8.752818908188624]);

        setTimeout(function () {
            chart3.zoom([14.071648010908019,19.6951145725961]);
            chart3.toggle('August')
        }, 3500);

        setTimeout(function () {
            chart3.toggle('Februar')
            chart3.toggle('Juli')
            chart3.zoom([26.20053861878251,30.288721535164616]);
        }, 7500);

        setTimeout(function () {
            chart3.zoom([-0.3,30.3]);
        }, 12000);

        setTimeout(function () {
        chart3.toggle('August')
        chart3.toggle('Februar')
        chart3.toggle('Juli')
        }, 15000);
    }

    $('form[id^=diagrams]').submit(function(e) {
        if($(this).closest('form')[0].checkValidity()){
            e.preventDefault();
            var data = $(this).serialize();
            $.ajax({
                url: '/contacts',
                type: 'post',
                data: data
            }).done(function() {
                $('#success').modal('show');
                $('.email-field').prop('disabled', true);
                $('.submit-button').prop('disabled', true);
            }).fail(function(jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON.errors[0] == 'Bitte mindestens ein Feld ausfüllen') {
                    var errorHtml = '';
                    $(jqXHR.responseJSON.errors).each(function(i, error) {
                        errorHtml += '<li>' + error + '</li>';
                    });
                    $('#fail-content').html('<ul>' + errorHtml + '</ul>');
                }
                $('#fail').modal('show');
            });
        }
    });

    if ($('#graph-container').length) {
        if (graphJSON.length < 1 || graphJSON.edges.length < 1) {
            $('#graph-container').text('Es gibt keine Daten für diesen Graph.');
            //$('#graph-container').css('height', 'auto');
            return;
        }

        const container = document.getElementById("graph-container")

        const graph = new Graph();

        graph.import(data)

        // node sizes
        const sizes = graph.nodes().map(function (node) { return graph.getNodeAttribute(node, 'size') })
        const minNodeSize = Math.min.apply(Math, sizes)
        const maxNodeSize = Math.max.apply(Math, sizes)
        const minSize = 2, maxSize = 14;
        graph.updateEachNodeAttributes((node, attr) => {
            //attr.size
            return {
                ...attr,
                size: minSize + ((attr.size - minNodeSize) / (maxNodeSize - minNodeSize)) * (maxSize - minSize)
            }
        })

        const layout = new FA2Layout(graph, {settings: {gravity: 1, barnesHutOptimize: true, adjustSizes: true, scalingRatio: 100}, weighted: true});

        var zoomInBtn = document.getElementById("zoom-in");
        var zoomOutBtn = document.getElementById("zoom-out");
        var zoomResetBtn = document.getElementById("zoom-reset");
        var renderer = new Sigma(graph, container, {
            minCameraRatio: 0.1,
            maxCameraRatio: 10,
        });
        var camera = renderer.getCamera();
        // Bind zoom manipulation buttons
        zoomInBtn.addEventListener("click", function () {
            camera.animatedZoom({ duration: 600 });
        });
        zoomOutBtn.addEventListener("click", function () {
            camera.animatedUnzoom({ duration: 600 });
        });
        zoomResetBtn.addEventListener("click", function () {
            camera.animatedReset({ duration: 600 });
        });


        renderer.getMouseCaptor().on('wheel', (e) => {
            e.preventDefault()
        })

        "use strict";
        /**
         * This example showcases sigma's reducers, which aim to facilitate dynamically
         * changing the appearance of nodes and edges, without actually changing the
         * main graphology data.
         */
        var __assign = (this && this.__assign) || function () {
            __assign = Object.assign || function(t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                        t[p] = s[p];
                }
                return t;
            };
            return __assign.apply(this, arguments);
        };
        //Object.defineProperty(exports, "__esModule", { value: true });

        var state = { searchQuery: "" };
        function setHoveredNode(node) {
            if (node) {
                state.hoveredNode = node;
                state.hoveredNeighbors = new Set(graph.neighbors(node));
            }
            else {
                state.hoveredNode = undefined;
                state.hoveredNeighbors = undefined;
            }
            // Refresh rendering:
            renderer.refresh();
        }
        // Bind graph interactions:
        renderer.on("enterNode", function (_a) {
            var node = _a.node;
            setHoveredNode(node);
        });
        renderer.on("leaveNode", function () {
            setHoveredNode(undefined);
        });
        // Render nodes accordingly to the internal state:
        // 1. If a node is selected, it is highlighted
        // 2. If there is query, all non-matching nodes are greyed
        // 3. If there is a hovered node, all non-neighbor nodes are greyed
        renderer.setSetting("nodeReducer", function (node, data) {
            const res = __assign({}, data)
            if (state.hoveredNeighbors && !state.hoveredNeighbors.has(node) && state.hoveredNode !== node) {
                res.hidden = true
            }
            if (state.selectedNode === node) {
                res.highlighted = true
            }
            else if (state.suggestions && !state.suggestions.has(node)) {
                res.hidden = true
            }
            return res;
        });
        // Render edges accordingly to the internal state:
        // 1. If a node is hovered, the edge is hidden if it is not connected to the
        //    node
        // 2. If there is a query, the edge is only visible if it connects two
        //    suggestions
        renderer.setSetting("edgeReducer", function (edge, data) {
            var res = __assign({}, data);
            if (state.hoveredNode && !graph.hasExtremity(edge, state.hoveredNode)) {
                res.hidden = true;
            }
            if (state.suggestions && (!state.suggestions.has(graph.source(edge)) || !state.suggestions.has(graph.target(edge)))) {
                res.hidden = true;
            }
            return res;
        });

        renderer.setSetting("labelRenderedSizeThreshold", 0);

        button4.addEventListener('click', e => {
            animation_4()
            if (layout.isRunning() === true) {
                e.currentTarget.textContent = 'Animation läuft'
                button4.style.background = '#c2e05e'
                button4.style.color = '#000'
            } else {
                button4.innerHTML = "Animieren"
                button4.style.background = '#198754'
                button4.style.color = '#fff'
            }})

         function animation_4(){
             if (layout.isRunning() === true) {
                 layout.stop()
             }
             else {
                 layout.start()
             }
         }
    }
})
